import {
  type ComponentProps,
  type ReactNode,
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useHover } from "~components/hooks/useHoover";
import mergeRefs from "~lib/components/mergeRefs";
import { cn, useScreenSize } from "~utils";
import { usePictureInPicture } from "~utils/video";
import { VideoPlayButton } from "./video-play-button";

type VideoElementProps = Pick<
  ComponentProps<"video">,
  "disablePictureInPicture" | "controls"
>;

export interface VideoImperativeControls {
  play: () => void;
  pause: () => void;
  mute: () => void;
  unmute: () => void;
}

export type VideoPlayerProps = {
  src: string;
  poster?: string;
  captions?: string;
  locale?: string;
  isAutoplay?: boolean;
  isLoop?: boolean;
  className?: string;
  classNameVideo?: string;
  disablePictureInPicture?: boolean;
  controls?: boolean;
  videoRef?: Ref<VideoImperativeControls>;

  mute?: ({
    onClick,
    isHover,
    isMuted,
  }: {
    onClick: () => void;
    isHover: boolean;
    isMuted: boolean;
  }) => ReactNode;

  play?: ({
    onClick,
    isPlaying,
    isHover,
    isEnded,
  }: {
    onClick: () => void;
    isHover: boolean;
    isPlaying: boolean;
    isEnded: boolean;
  }) => ReactNode;
} & VideoElementProps;

export const VideoPlayer = forwardRef<HTMLDivElement, VideoPlayerProps>(
  (
    {
      src,
      poster,
      captions,
      className,
      classNameVideo,
      isAutoplay = false,
      isLoop = false,
      controls = true,
      disablePictureInPicture = false,
      play,
      mute,
      videoRef: _videoRef,
      ...context
    },
    ref,
  ) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const trackRef = useRef<HTMLTrackElement | null>(null);
    const hoverRef = useRef<HTMLDivElement>(null);
    const isHover = useHover(hoverRef);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(isAutoplay);
    const [isEnded, setIsEnded] = useState(false);

    const { isMobile } = useScreenSize();

    // Create captions file, and set it on video
    useEffect(() => {
      if (!captions || !trackRef.current) return;

      const blob = new Blob([captions], { type: "text/vtt" });
      const url = URL.createObjectURL(blob);
      trackRef.current.src = url;
    }, [captions]);

    // Enables picture in picture when the user scrolls away from a playing section.
    usePictureInPicture({ videoRef, isAutoplay });

    useImperativeHandle(_videoRef, () => ({
      play: () => {
        if (videoRef.current) {
          setIsPlaying(true);
          videoRef.current.play();
        }
      },
      pause: () => {
        if (videoRef.current) {
          setIsPlaying(false);
          videoRef.current.pause();
        }
      },
      mute: () => {
        if (videoRef.current) {
          videoRef.current.muted = true;
          setIsMuted(true);
        }
      },
      unmute: () => {
        if (videoRef.current) {
          videoRef.current.muted = false;
          setIsMuted(false);
        }
      },
    }));

    return (
      <div
        className={cn("relative w-full overflow-hidden rounded-md", className)}
        ref={mergeRefs(hoverRef, ref)}
      >
        <video
          ref={videoRef}
          controls={controls}
          poster={poster}
          autoPlay={isAutoplay}
          muted={isAutoplay}
          loop={isLoop}
          onPlay={() => {
            setIsPlaying(true);
            setIsEnded(false);
          }}
          onPause={() => setIsPlaying(false)}
          onEnded={() => setIsEnded(true)}
          className={cn("h-full w-full rounded-md", classNameVideo)}
          disablePictureInPicture
        >
          <source src={src} />

          {captions && (
            <track
              ref={trackRef}
              kind="captions"
              srcLang={context.locale}
              default
            />
          )}
        </video>
        {/* We only show the play icon on desktop, as mobile has its own UI handling this. */}
        {!isMobile && !isPlaying && controls && (
          <VideoPlayButton
            onClick={() => {
              setIsPlaying(true);
              videoRef.current?.play();
            }}
            isPlaying={isPlaying}
            isEnded={isEnded}
          />
        )}

        {play?.({
          isEnded,
          isHover,
          isPlaying,
          onClick: () => {
            if (isPlaying) {
              setIsPlaying(false);
              videoRef.current?.pause();
            } else {
              setIsPlaying(true);
              videoRef.current?.play();
            }
          },
        })}

        {mute?.({
          isHover,
          isMuted,
          onClick: () => {
            if (isMuted) {
              if (!videoRef.current) return;
              videoRef.current.muted = false;
              setIsMuted(false);
            } else {
              if (!videoRef.current) return;
              videoRef.current.muted = true;
              setIsMuted(true);
            }
          },
        })}
      </div>
    );
  },
);

VideoPlayer.displayName = "VideoPlayer";
