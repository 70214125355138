import type { ComponentProps } from "react";
import { cn } from "~utils";
import { Icon } from "../icon";

interface VideoVolumeButtonProps extends ComponentProps<"button"> {
  isMuted: boolean;
}

function VideoVolumeButton({
  isMuted,
  className,
  ...props
}: VideoVolumeButtonProps) {
  return (
    <button
      type="button"
      className={cn("text-others-white", className)}
      {...props}
    >
      {!isMuted ? (
        <Icon
          name="SpeakerHigh"
          weight="duotone"
          className="circle-icon-sm"
          aria-hidden
        />
      ) : (
        <Icon
          name="SpeakerSlash"
          weight="duotone"
          className="circle-icon-sm"
          aria-hidden
        />
      )}
    </button>
  );
}

export { VideoVolumeButton };
