import type { ComponentProps } from "react";
import { cn } from "~utils";
import { Icon } from "../icon";

interface VideoPlayButtonProps extends ComponentProps<"button"> {
  isPlaying?: boolean;
  isEnded?: boolean;
}

function VideoPlayButton({
  isPlaying,
  isEnded,
  className,
  ...props
}: VideoPlayButtonProps) {
  return (
    <button
      type="button"
      className={cn(
        "-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 text-others-white",
        className,
      )}
      {...props}
    >
      {!isEnded &&
        (!isPlaying ? (
          <Icon
            name="Play"
            weight="duotone"
            className="circle-icon"
            aria-hidden
          />
        ) : (
          <Icon
            name="Pause"
            weight="duotone"
            className="circle-icon"
            aria-hidden
          />
        ))}

      {isEnded && (
        <Icon
          name="ArrowClockwise"
          weight="duotone"
          className="circle-icon"
          aria-hidden
        />
      )}
    </button>
  );
}

export { VideoPlayButton };
